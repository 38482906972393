import React, { useState } from "react"
import styled from "styled-components"
import { FaAngleRight, FaAngleDown } from "react-icons/fa"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import MobileMenuButton from "./button"
import SubMenu from "./subMenu"

const Navigation = ({ links, isOpen, setIsOpen }) => {
  const [activeNav, setActiveNav] = useState(undefined)
  const { pathname } = useLocation()

  const handleNavClick = navNumber => {
    !activeNav && activeNav !== navNumber
      ? setActiveNav(navNumber)
      : setActiveNav(undefined)
  }

  const subLinkIsActive = links => {
    const isActive = links.find(link => pathname.includes(link.page.uid))
    return !!isActive
  }
  return (
    <Container>
      {links.map((link, i) => {
        if (!link.items[0].link_text) {
          return (
            <LinkContainer
              onClick={() => handleNavClick(i)}
              key={link.items[0].page.uid}
              active={pathname.includes(link.items[0].page.uid)}
            >
              <StyledLink
                to={`/${link.items[0].page.uid}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {link.primary.link_group_name}
              </StyledLink>

              <Icon>
                <FaAngleRight />
              </Icon>
            </LinkContainer>
          )
        } else {
          const isActive = subLinkIsActive(link.items)
          return (
            <LinkContainer
              onClick={() => handleNavClick(i)}
              key={link.items[0].page.uid}
              active={isActive}
            >
              <LinkGroupLabel>{link.primary.link_group_name}</LinkGroupLabel>
              <Icon>
                {activeNav === i ? <FaAngleDown /> : <FaAngleRight />}
              </Icon>
              {activeNav === i && <SubMenu links={link.items} />}
            </LinkContainer>
          )
        }
      })}

      <ButtonsContainer>
        <MobileMenuButton
          text="book now"
          href="https://www.playalinda.com/stay-with-us"
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <MobileMenuButton
          text="Owners Portal"
          href="https://rdpportal.playalinda.com/owner/index.html?resort=02"
          //href="https://www.playalinda.com/the-owners-portal-is-currently-under-maintenance"
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <MobileMenuButton
          text="Bulletin Board"
          href="https://forum.playalinda.com/"
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </ButtonsContainer>
    </Container>
  )
}

export default Navigation

const Container = styled.div`
  background: var(--primary-text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div:last-child {
    margin: 50px 0;
  }
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 32px;
  min-width: 220px;
`
const LinkContainer = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-columns: auto 24px;
  width: 100%;
  align-items: center;
  ${({ active }) =>
    active &&
    `background: rgba(255, 255, 255, 0.54);
color: #fff;`}
  &:hover {
    background: rgba(255, 255, 255, 0.54);
    color: #fff;
  }
`
const StyledLink = styled(Link)`
  display: block;
  justify-self: center;
  color: #fff;
  width: 100%;
  text-decoration: none;
  text-align: center;
  font-size: 1.5rem;
  font-family: "Moderat Light", sans-serif;
  padding: 16px 0 16px 0;
  text-transform: capitalize;
`
const Icon = styled.div``

const LinkGroupLabel = styled.div`
  display: block;
  justify-self: center;
  color: #fff;
  width: max-content;
  text-decoration: none;
  text-align: center;
  font-size: 1.5rem;
  font-family: "Moderat Light", sans-serif;
  padding: 16px 0 16px 0;
  text-transform: capitalize;
`
